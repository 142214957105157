<template>
  <div>
    <v-row>
      <v-col cols="12">
        <CorrectDataTable
          :table-headers="accident_report_header"
          :table-data="accident_report_list"
          :page="tablePage"
          :page-limit="tablePageLimit"
          :page-limit-options="tablePageLimitOptions"
          :item-total="total_items"
          :is-loading="load_table"
          :enable-search="false"
          :enable-filter="false"
          :enable-date-range="false"
          :enable-clear-all="false"
          :disable-pagination="load_table"
          :disable-sort="true"
          @options-update="onTableChange"
        ></CorrectDataTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CorrectDataTable from '@/components/CorrectDataTable.vue'
import { getData } from '@/api'
export default {
  name: 'AccidentReport',
  components: {
    CorrectDataTable,
  },
  data: () => ({
    accident_report_header: [],
    accident_report_list: [],
    load_table: true,
    tablePage: 1,
    tablePageLimit: 10,
    tablePageLimitOptions: [10, 20, 50],
    total_items: 0,
  }),
  methods: {
    initializeHeader() {
      this.accident_report_header = [
        {
          text: this.$t('job_post.create_date'),
          value: 'create_date',
        },
        {
          text: `${this.$t('worker.worker_id')}/${this.$t('customer.customer_id')}`,
          value: 'user_id',
        },
        {
          text: this.$t('general.content'),
          value: 'content',
        },
      ]
    },
    async initializeData() {
      this.load_table = true
      try {
        let send_data = { page: this.tablePage - 1, limit: this.tablePageLimit }
        let params = new URLSearchParams()
        params.set('cms_get_report_accident', JSON.stringify(send_data))
        let get_report_accident_data = await getData(params)
        this.$func.log('-----Get Accident Report Data-----')
        this.$func.log(get_report_accident_data)

        if (!Array.isArray(get_report_accident_data)) {
          this.total_items = get_report_accident_data.total

          let bugs_data = get_report_accident_data.data

          let temp = []
          for (let i = 0; i < bugs_data.length; i++) {
            temp.push({
              id: bugs_data[i].id,
              create_date: bugs_data[i].create_date,
              user_id: bugs_data[i].user_id,
              content: bugs_data[i].content,
            })
          }

          this.accident_report_list = temp
        }
        this.load_table = false
      } catch (error) {
        this.$func.log(error)
        this.load_table = false
      }
    },
    onTableChange(options) {
      if (options.itemsPerPage !== this.tablePageLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }
      if (options.sortBy.length > 0) {
        if (options.sortBy[0].indexOf('user_data.') === 0) {
          this.tableSortKey = options.sortBy[0].substring(10)
        } else {
          this.tableSortKey = options.sortBy[0]
        }
      }

      this.tablePageLimit = options.itemsPerPage
      this.initializeData()
    },
  },
  async created() {
    const fetchAllow = await this.checkToken()
    if (fetchAllow) {
      this.initializeHeader()
      this.initializeData()
    }
  },
}
</script>